<template>
  <div class="swiper-slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HkSwiperSlide',
  mounted () {
    this.update()
  },
  updated () {
    this.update()
  },
  methods: {
    update () {
      this.$parent && this.$parent.instance && this.$parent.update()
    }
  }
}
</script>
